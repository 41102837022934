var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "CRow",
        { staticClass: "video-part" },
        [
          _c(
            "CCol",
            { attrs: { md: "8" } },
            [
              _c(
                "ValidatedFormCard",
                {
                  ref: "validatedFormCard",
                  attrs: {
                    titleTranslationKey:
                      "components.playroomEditParts.VideoPart.title",
                    titleTooltipTranslationKey:
                      "components.playroomEditParts.VideoPart.tooltip",
                    ready: _vm.ready,
                    model: _vm.$v,
                    onSave: _vm.save
                  },
                  on: {
                    "part-save-status-type-change": function(status) {
                      return _vm.$emit("part-save-status-type-change", status)
                    }
                  }
                },
                [
                  _c(
                    "CForm",
                    [
                      _c(
                        "CRow",
                        [
                          _c(
                            "CCol",
                            { attrs: { md: "6" } },
                            [
                              _c("ValidatedSelect", {
                                attrs: {
                                  options: _vm.availableVideoTypes,
                                  field: _vm.$v.playroom.videoType,
                                  value: _vm.$v.playroom.videoType.$model,
                                  translationKey: "playroom.videoType",
                                  "data-cy": "videoType-field"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.videoType,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "CCol",
                            { attrs: { md: "6" } },
                            [
                              _c("ValidatedInput", {
                                attrs: {
                                  field: _vm.$v.playroom.videoUrl,
                                  value: _vm.$v.playroom.videoUrl.$model,
                                  translationKey: "playroom.videoUrl",
                                  "data-cy": "videoUrl-field"
                                },
                                on: {
                                  "update:value": function($event) {
                                    return _vm.$set(
                                      _vm.$v.playroom.videoUrl,
                                      "$model",
                                      $event
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }