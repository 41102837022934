var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "name-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.NamePart.title",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _c(
                "CForm",
                [
                  _c("ValidatedInput", {
                    attrs: {
                      translationKey: "playroom.name",
                      "data-cy": "name-field",
                      field: _vm.$v.playroom.name,
                      value: _vm.$v.playroom.name.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(_vm.$v.playroom.name, "$model", $event)
                      },
                      focusChange: _vm.onFocusChange
                    }
                  }),
                  _c("ValidatedInput", {
                    attrs: {
                      translationKey: "playroom.shortName",
                      "data-cy": "short-name-field",
                      field: _vm.$v.playroom.shortName,
                      value: _vm.$v.playroom.shortName.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.playroom.shortName,
                          "$model",
                          $event
                        )
                      },
                      focusChange: _vm.onFocusChange
                    }
                  }),
                  _c("ValidatedTextArea", {
                    attrs: {
                      translationKey: "playroom.shortDescription",
                      "data-cy": "short-description-field",
                      rows: "5",
                      field: _vm.$v.playroom.shortDescription,
                      value: _vm.$v.playroom.shortDescription.$model
                    },
                    on: {
                      "update:value": function($event) {
                        return _vm.$set(
                          _vm.$v.playroom.shortDescription,
                          "$model",
                          $event
                        )
                      },
                      focusChange: _vm.onFocusChange
                    }
                  }),
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey: "playroom.areaInSquareMeter",
                              field: _vm.$v.playroom.areaInSquareMeter,
                              value: _vm.$v.playroom.areaInSquareMeter.$model,
                              "data-cy": "area-in-square-meters-field",
                              type: "number",
                              min: "1"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.areaInSquareMeter,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey: "playroom.roomCount",
                              field: _vm.$v.playroom.roomCount,
                              value: _vm.$v.playroom.roomCount.$model,
                              "data-cy": "roomCount-field",
                              type: "number",
                              min: "1",
                              step: "1"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.roomCount,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _c("CCol", { attrs: { md: "6" } }),
                      _c(
                        "CCol",
                        { attrs: { md: "12" } },
                        [
                          _c("ValidatedSelect", {
                            attrs: {
                              translationKey: "playroom.propertyType",
                              field: _vm.$v.playroom.propertyType,
                              value: _vm.$v.playroom.propertyType.$model,
                              "data-cy": "propertyType-field",
                              options: _vm.propertyTypeOptions
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.propertyType,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey: "playroom.urlPath",
                              field: _vm.$v.playroom.urlPath,
                              value: _vm.$v.playroom.urlPath.$model,
                              "data-cy": "urlPath-field"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.urlPath,
                                  "$model",
                                  $event
                                )
                              },
                              focusChange: _vm.onFocusChange
                            }
                          })
                        ],
                        1
                      ),
                      _c("CCol", { attrs: { md: "6" } }, [
                        _c(
                          "div",
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.playroomEditParts.NamePart.publicUrl"
                                  )
                                ) +
                                ": "
                            ),
                            _c("CIcon", {
                              directives: [
                                {
                                  name: "c-tooltip",
                                  rawName: "v-c-tooltip",
                                  value: {
                                    appendToBody: true,
                                    content: this.$t(
                                      "components.playroomEditParts.NamePart.publicUrlInfo"
                                    )
                                  },
                                  expression:
                                    "{\n                  appendToBody: true,\n                  content: this.$t('components.playroomEditParts.NamePart.publicUrlInfo'),\n                }"
                                }
                              ],
                              attrs: { name: "cis-info-circle" }
                            })
                          ],
                          1
                        ),
                        _c("div", [
                          _c("a", { attrs: { href: _vm.publicUrl } }, [
                            _vm._v(_vm._s(_vm.publicUrl))
                          ])
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name: "components.playroomEditParts.NamePart.info.box1"
            }
          }),
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name: "components.playroomEditParts.NamePart.info.box2"
            }
          }),
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name: "components.playroomEditParts.NamePart.info.box3"
            }
          }),
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name: "components.playroomEditParts.NamePart.info.box4"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }