var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "address-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.AddressPart.title",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _c("AddressForm", {
                attrs: {
                  field: _vm.$v.playroom,
                  value: _vm.$v.playroom.$model
                },
                on: {
                  "update:value": function($event) {
                    return _vm.$set(_vm.$v.playroom, "$model", $event)
                  },
                  change: _vm.onChange,
                  focusChange: _vm.onFocusChange
                }
              }),
              _c(
                "CRow",
                { staticClass: "positionFields" },
                [
                  _c(
                    "CCol",
                    { attrs: { md: "6" } },
                    [
                      _c("ValidatedInput", {
                        attrs: {
                          disabled: "",
                          field: _vm.$v.playroom.latitude,
                          translationKey: "playroom.latitude",
                          value: _vm.$v.playroom.latitude.$model
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.playroom.latitude,
                              "$model",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { attrs: { md: "6" } },
                    [
                      _c("ValidatedInput", {
                        attrs: {
                          disabled: "",
                          field: _vm.$v.playroom.longitude,
                          translationKey: "playroom.longitude",
                          value: _vm.$v.playroom.longitude.$model
                        },
                        on: {
                          "update:value": function($event) {
                            return _vm.$set(
                              _vm.$v.playroom.longitude,
                              "$model",
                              $event
                            )
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c("ValidatedCheckbox", {
                attrs: {
                  field: _vm.$v.playroom.hideAddressTillConfirmation,
                  checked: _vm.$v.playroom.hideAddressTillConfirmation.$model,
                  translationKey: "playroom.hideAddressTillConfirmation"
                },
                on: {
                  "update:checked": function($event) {
                    return _vm.$set(
                      _vm.$v.playroom.hideAddressTillConfirmation,
                      "$model",
                      $event
                    )
                  }
                }
              }),
              !!_vm.playroom.latitude && !!_vm.playroom.longitude
                ? _c(
                    "div",
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.playroomEditParts.AddressPart.preview"
                            )
                          ) +
                          ": "
                      ),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "c-tooltip",
                              rawName: "v-c-tooltip",
                              value: {
                                appendToBody: true,
                                content: _vm.$t(
                                  "components.playroomEditParts.AddressPart.previewInfo"
                                )
                              },
                              expression:
                                "{\n            appendToBody: true,\n            content: $t('components.playroomEditParts.AddressPart.previewInfo'),\n          }"
                            }
                          ]
                        },
                        [_c("CIcon", { attrs: { name: "cis-info-circle" } })],
                        1
                      ),
                      _c(
                        "GmapMap",
                        {
                          staticStyle: { width: "100%", height: "300px" },
                          attrs: {
                            center: {
                              lat: _vm.playroom.latitude,
                              lng: _vm.playroom.longitude
                            },
                            zoom: 10,
                            options: {
                              streetViewControl: false
                            }
                          }
                        },
                        [
                          !_vm.playroom.hideAddressTillConfirmation
                            ? _c("GmapMarker", {
                                attrs: {
                                  position: {
                                    lat: _vm.playroom.latitude,
                                    lng: _vm.playroom.longitude
                                  },
                                  draggable: true
                                },
                                on: { dragend: _vm.positionChanged }
                              })
                            : _vm._e(),
                          _vm.playroom.hideAddressTillConfirmation
                            ? _c("GmapCircle", {
                                attrs: {
                                  center: {
                                    lat: _vm.playroom.latitude,
                                    lng: _vm.playroom.longitude
                                  },
                                  options: {
                                    strokeWeight: 0,
                                    fillOpacity: 0.2
                                  },
                                  radius: 5 * 1000,
                                  draggable: true
                                },
                                on: { dragend: _vm.positionChanged }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "CCol",
        { attrs: { md: "4" } },
        [
          _c("HelpCard", {
            attrs: {
              focusFor: _vm.focusFor,
              name: "components.playroomEditParts.AddressPart.info.box1"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }