var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.playroomId
    ? _c(
        "div",
        { staticClass: "owner-playroom-edit-basic-information" },
        [
          _c("NamePart", {
            ref: "namePart",
            attrs: { playroomId: _vm.playroomId },
            on: {
              "part-save-status-type-change": function(status) {
                return (_vm.namePartSaveStatusType = status)
              }
            }
          }),
          _c("ContactPart", {
            ref: "contactPart",
            attrs: { playroomId: _vm.playroomId },
            on: {
              "part-save-status-type-change": function(status) {
                return (_vm.contactPartSaveStatusType = status)
              }
            }
          }),
          _c("VideoPart", {
            ref: "videoPart",
            attrs: { playroomId: _vm.playroomId },
            on: {
              "part-save-status-type-change": function(status) {
                return (_vm.videoPartSaveStatusType = status)
              }
            }
          }),
          _c("AddressPart", {
            ref: "addressPart",
            attrs: { playroomId: _vm.playroomId },
            on: {
              "part-save-status-type-change": function(status) {
                return (_vm.addressPartSaveStatusType = status)
              }
            }
          }),
          _c(
            "CRow",
            [
              _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c("CButton", {
                    directives: [
                      {
                        name: "t",
                        rawName: "v-t",
                        value: "global.saveAndNext",
                        expression: "'global.saveAndNext'"
                      }
                    ],
                    staticClass: "saveAndNext",
                    attrs: { color: "primary", disabled: !_vm.canSave },
                    on: { click: _vm.onNext }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("Notification", {
            attrs: {
              text: _vm.$t(
                "pages.owner.playrooms.edit.OwnerPlayroomEditBasicInformation.newPlayroomInfo",
                {
                  playroomName: _vm.playroom.name
                }
              ),
              show: _vm.showNewPlayroomInformation
            },
            on: {
              onClose: function() {
                return (_vm.showNewPlayroomInformation = false)
              }
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }