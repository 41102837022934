var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CRow",
    { staticClass: "contact-part" },
    [
      _c(
        "CCol",
        { attrs: { md: "8" } },
        [
          _c(
            "ValidatedFormCard",
            {
              ref: "validatedFormCard",
              attrs: {
                titleTranslationKey:
                  "components.playroomEditParts.ContactPart.title",
                titleTooltipTranslationKey:
                  "components.playroomEditParts.ContactPart.tooltip",
                ready: _vm.ready,
                model: _vm.$v,
                onSave: _vm.save
              },
              on: {
                "part-save-status-type-change": function(status) {
                  return _vm.$emit("part-save-status-type-change", status)
                }
              }
            },
            [
              _c(
                "CForm",
                [
                  _c(
                    "CRow",
                    [
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.email,
                              value: _vm.$v.playroom.email.$model,
                              translationKey: "playroom.email",
                              autocomplete: "email",
                              "data-cy": "email-field"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.email,
                                  "$model",
                                  $event
                                )
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-content",
                                fn: function() {
                                  return [_vm._v("@")]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.phoneNumber,
                              value: _vm.$v.playroom.phoneNumber.$model,
                              translationKey: "playroom.phoneNumber",
                              "data-cy": "phoneNumber-field"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.phoneNumber,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "CCol",
                        { attrs: { md: "6" } },
                        [
                          _c("ValidatedInput", {
                            attrs: {
                              field: _vm.$v.playroom.website,
                              value: _vm.$v.playroom.website.$model,
                              translationKey: "playroom.website",
                              "data-cy": "website-field"
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.playroom.website,
                                  "$model",
                                  $event
                                )
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend-content",
                                fn: function() {
                                  return [
                                    _c("CIcon", { attrs: { name: "cilGlobe" } })
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }